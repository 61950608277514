import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/de/layout"
//import Image from "../components/image"
import SEO from "../components/de/seo"

import MainImg from "./../images/main_bg.jpg"

import shape1 from "./../images/dec/1.png"
import shape2 from "./../images/dec/2.png"
import shape3 from "./../images/dec/3.png"
import shape4 from "./../images/dec/4.png"
import shape5 from "./../images/dec/5.png"
import shape6 from "./../images/dec/6.png"
import shape7 from "./../images/dec/7.png"
import shape8 from "./../images/dec/8.png"

const Impresum = () => (
  <Layout>
    <SEO
      title="MOONAMAR - IMPRESSUM"
      description="MOONAMAR – Adresse: Rautenweg 22, 1220 Wien, Österreich / E-Mail: office@moonamar.co / Tel.: +43 699 170 22 482"
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">IMPRESSUM</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section _impresum">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <h2 className="subtitle is-4 is-spaced">MOONAMAR e.U.</h2>
                <p className="title is-6">ADRESSE</p>
                <p className="subtitle is-spaced">Rautenweg 22</p>
                <p className="title is-6">WEB</p>
                <p className="subtitle is-spaced">
                  <a href="www.moonamar.co">www.moonamar.co</a>
                </p>
                <p className="title is-6">E-MAIL</p>
                <p className="subtitle is-spaced">
                  <a href="mailto:office@moonamar.co">office@moonamar.co</a>
                </p>
                <p className="title is-6">TELEFON</p>
                <p className="subtitle is-spaced">
                  <a href="tel:+43 699 170 22 482">+43 699 170 22 482</a> (Viber
                  / WhatsApp)
                </p>
                <p className="title is-6">
                  UNTERNEHMENS-INDENTIFIKATIONSNUMMER
                </p>
                <p className="subtitle is-spaced">
                  UID: ATU722701179 <br />
                  FN: 473561p
                </p>
                <p className="title is-6">REGISTERGERICHT</p>
                <p className="subtitle is-spaced">Handelsgericht Wien</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Impresum
